import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/TransactionReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import TransactionDetails from "./TransactionDetails";
import { format } from "date-fns";

const TransactionReportContent = createContext();


axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              if(num >= 0 || num <= 0){
                  return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
              }
          }
      }
      let Transaction = this.props.Transaction;
      const TransactionDetailData = this.props.TransactionDetailData;
      const today = Date.now();
      const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
      return (
          <div className="PaymentBillContent">
              <div className="imgQR">
                <img className="logo" src={logo} />
              </div>
              <div className="BillHeader">
                  <div className="BillTitle">
                      <span>Mertrade</span>
                  </div>
                  <div className="BillNO">
                      <span>BillNO # {Transaction.TransactionID}</span>
                  </div>
                  <div className="Cashier">
                      <span>Cashier: {Transaction.UserName}</span>
                  </div>

                  <div className="ShowDate">
                      <span>Date: {(Transaction.CreatedDate)}</span>
                  </div>
                  <div className="LocationName">
                      <span>Location: {Transaction.LocationName}</span>
                  </div>
              </div>
              <div className="BillBody">
                  <div className="BodyHeader">
                      <span className="CountNumber">NO.</span>
                      <span className="ProductName">Price</span>
                      <span className="Qty">Qty</span>
                      <span className="Discount">Discount</span>
                      <span className="Price">Total</span>
                  </div>
                  <div className="HeadLine">
                      <div className="footer-line"></div>
                      <div className="footer-line"></div>
                  </div>
                  <div className="BodyDetails">
                      {
                          TransactionDetailData && TransactionDetailData.map((x, y) => {
                              return (
                                  <div className="Items" key={y}>
                                      <div className="Name">
                                          <span className="ProductName">{x.EngName}</span>
                                      </div>
                                      <div className="Detail">
                                          <div className="mds">
                                              <span className="NO">{(y + 1) }</span>
                                              <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                              <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                              <span className="Discount">{currencyFormat(x.TotalDiscount)}</span>
                                              <span className="Price">{currencyFormat(Number(x.SubTotal) - Number(x.TotalDiscount))}</span>
                                          </div>
                                      </div>
                                      <div className="footer-line"></div>
                                  </div>
                              )
                          })
                      }
                  </div>

              </div>
              <div className="BillFooter">
                  <div className="footer-line"></div>
                  <div className="subtotal">
                      <span>SubTotal:</span>
                      <strong>{currencyFormat(Transaction.SubTotal)}</strong>
                  </div>
                  <div className="discout">
                      <span>Discount:</span>
                      <strong>{currencyFormat(Transaction.TotalDiscount)}</strong>
                  </div>
                  <div className="nettotal">
                      <span>NetTotal:</span>
                      <strong>{currencyFormat(Transaction.NetTotal)}</strong>
                  </div>
                  <div className="footer-line"></div>
                  <div className="footer-line"></div>
                  <div className="paymentType">
                      <span>PaymentType:</span>
                      <strong>{Transaction.PaymentType}</strong>
                  </div>
                  <div className="paidAmount">
                      <span>PaidAmount:</span>
                      <strong>{currencyFormat(Transaction.PaidAmount)}</strong>
                  </div>
                  {
                      Transaction.PaidMoreAmount > 0 && 
                      <div className="PaidMoreAmount">
                          <span>PaidMoreAmount:</span>
                          <strong>{currencyFormat(Transaction.PaidMoreAmount)}</strong>
                      </div>
                      
                  }
                  <div className="changeAmount">
                      <span>Change:</span>
                      <strong>{currencyFormat(Transaction.ChangeAmount)}</strong>
                  </div>
                  
                  <div className="footer-line"></div>
                  <div className="footer-line"></div>
                  <div className="TaxBeforeVat">
                      <span>Total Before VAT:</span>
                      <strong>{currencyFormat((Number(Transaction.NetTotal) - Number(Transaction.Tax) ))}</strong>
                  </div>
                  <div className="VAT">
                      <span>VAT(10%):</span>
                      <strong>{currencyFormat(Transaction.Tax)}</strong>
                  </div>
                  <div className="TotalTax">
                      <span>Total:</span>
                      <strong>{currencyFormat(Transaction.NetTotal)}</strong>
                  </div>
                  <div className="footer-line"></div>
                  <div className="Thankyou">
                      <span>Thank you!</span>
                  </div>
              </div>
          </div>
      );
  }
}

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let SubTotal = 0;
      let Discount = 0;
      let NetTotal = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="TransactionReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບການຂາຍ</span>
                      </div>
                      
                  </div>
                  <div className="PrintDate">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
                  <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="BillNo">ເລກບິນ</span>
                      <span className="PaymentType">ປະເພດຈ່າຍ</span>
                      <span className="SubTotal">ຍອດລວມ</span>
                      <span className="Discount">ສ່ວນຫລຸດ</span>
                      <span className="NetTotal">ຍອດທີ່ຕ້ອງຈ່າຍ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"/>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal = Number(x.SubTotal) +  Number(SubTotal);
                            Discount =  Number(x.Discount) +  Number(Discount);
                            NetTotal =  Number(x.NetTotal) +  Number(NetTotal);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="BillNo">{x.TransactionID}</span>
                                            <span className="PaymentType">{x.PaymentType}</span>
                                            <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                            <span className="Discount">{currencyFormat(x.Discount)}</span>
                                            <span className="NetTotal">{currencyFormat(x.NetTotal)}</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                      <span className="Discount">{currencyFormat(Discount)}</span>
                      <span className="PaidAmount">{currencyFormat(NetTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function TransactionReport() {
  function getDate(gdate) {
    const today = gdate;
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return `${year}-${month}-${date} ${time}`;
  }

  function getDate2(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy HH:mm");
  }

  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const [LocationList, setLocationList] = useState(null); 
  const [LocationID, setLocationID] = useState(""); 
  const [LocationName, setLocationName] = useState("");
  const componentRef = useRef(); 
  const [TransactionDetailData, setTransactionDetailData] = useState([]);

  const [TransactionID, setTransactionID] = useState("");
  const [UserName, setUserName] = useState("");
  const [Location, setLocation] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [SubTotal, setSubTotal] = useState("");
  const [TotalDiscount, setTotalDiscount] = useState("");
  const [Tax, setTax] = useState("");
  const [NetTotal, setNetTotal] = useState("");
  const [PaidAmount, setPaidAmount] = useState("");
  const [PaidMoreAmount, setPaidMoreAmount] = useState("");
  const [ChangeAmount, setChangeAmount] = useState("");
  const [CreatedDate, setCreatedDate] = useState("");

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const PrintBill = useReactToPrint({
    content: () => componentRef.current,
});


  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}

const handleSelectLocation = (e) => {
  var data = LocationList.filter(function (item) {
      return item.LocationName == e.target.value
  })
  if (data.length > 0) {
      setLocationName(data[0].LocationName);
      setLocationID(data[0].LocationID);
  } else {
      setLocationName("");
      setLocationID("");
  }
};

  async function handleShowTransactionDetail (TransactionID) {
    await axios.get(`/getTransactionDetail/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        setTransactionDetail(Data);
        handleShow();
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function GetTransactionForPrintBill (TransactionID) {
    await axios.get(`/getTransactionForPrintBill/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        setTransactionID(TransactionID);
        setUserName(Data[0].Name);
        setLocation(Data[0].LocationName);
        setPaymentType(Data[0].PaymentType);
        setSubTotal(Data[0].SubTotal);
        setTotalDiscount(Data[0].Discount);
        setTax(Data[0].Tax);
        setNetTotal(Data[0].NetTotal);
        setPaidAmount(Data[0].PaidAmount);
        setPaidMoreAmount(Data[0].PaidMoreAmount);
        setChangeAmount(Data[0].ChangeAmount);
        setCreatedDate(getDate2(Data[0].CreatedDate));
        getTransactionDetail(TransactionID);
        
        PrintBill();
      } else {
        setTransactionID("");
        setUserName("");
        setLocation("");
        setPaymentType("");
        setSubTotal("");
        setTotalDiscount("");
        setTax("");
        setNetTotal("");
        setPaidAmount("");
        setPaidMoreAmount("");
        setChangeAmount("");
        getTransactionDetail([]);
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  async function getTransactionDetail (TransactionID) {
    await axios.get(`/getTransactionDetail/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        setTransactionDetailData(Data);
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function SearchTransaction() {
      var StatusID = 1;
      await axios.get(`/TransactionReport/${getDate(StartedDate)}/${getDate(EndedDate)}/${LocationID}/${StatusID}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("locationData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setLocationList(userRole);
    }
  }, []);

  return (
    <TransactionReportContent.Provider value={{TransactionDetail}}>
      <div className="formContent">
         <TransactionDetails show={show} onClick={handleClose}/>
        <div className="Header">
          <div className="title">
              <span>ລາຍງານບິນຂາຍ</span>
          </div>
          <div className="txtSearch">
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="Location">
                <label>ສາຂາ: </label>
                <select name="LocationID" onChange={(e) => handleSelectLocation(e)}
                    className={`input form-control`}>
                    <option
                        key={""}
                        value={""}
                    >
                        {'ເລືອກສາຂາ'}
                    </option>,
                    {LocationList && LocationList.map(item => (
                        <option
                            key={item.LocationID}
                            value={item.LocationName}
                        >
                            {item.LocationName}
                        </option>
                    ))}
                </select>
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຍອດທີ່ຕ້ອງຈ່າຍ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ຈ່າຍເພີ່ມ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TransactionID}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.PaidAmount}</td>
                        <td >{x.PaidMoreAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-primary" value={x.TransactionID} type="button" onClick={() => GetTransactionForPrintBill(x.TransactionID,)}>ພິມບິນ</button>
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => handleShowTransactionDetail(x.TransactionID,)}>ລາຍລະອຽດບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate , "LocationName": LocationName}} 
            />
            <ComponentToPrint ref={componentRef}
                    TransactionDetailData={TransactionDetailData}
                    Transaction={{"TransactionID": TransactionID, "UserName": UserName, 
                      "LocationName": LocationName, "PaymentType": PaymentType, "SubTotal": SubTotal, 
                      "TotalDiscount": TotalDiscount, "Tax": Tax, "NetTotal": NetTotal, "PaidAmount": PaidAmount,
                      "PaidMoreAmount": PaidMoreAmount, "ChangeAmount": ChangeAmount, "CreatedDate": CreatedDate}} 
            />
        </div>
      </div>
      </TransactionReportContent.Provider>
  );
};
export {TransactionReportContent};
export default TransactionReport;
