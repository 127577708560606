import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateProduct from "./create/createProduct";
import UpdateProduct from "./update/updateProduct";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import axios from "axios";
axios.defaults.baseURL = API;


const ProductContext = createContext();

function Product() {
  const {userID, LocationID} = useContext(UserContext);
  const [ProductList, setProductList] = useState([]);
  const [ProductTypeList, setProductTypeList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [BrandList, setBrandList] = useState([]);
  const [SizeTypeList, setSizeTypeList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function HandleSearchProductByBarcode (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchProductByBarcode/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        }else {
          setShowData(ProductList);
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
      setShowData(ProductList);
    }
  }

  async function HandleSearchProductByLaoName (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchProductByLaoName/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        } else {
          setShowData(ProductList);
        }
      }).catch((err) => {
          setShowData(ProductList);
          console.log(err);
      });
    } else {
      setShowData(ProductList);
    }
  }

  async function HandleSearchProductByEngName (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchProductByEngName/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        }else {
          setShowData(ProductList);
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
      setShowData(ProductList);
    }
  }

  const openUpdate = (ProductID, ProductTypeID, ProductType, CategoryID, Category, BrandID, Brand, Barcode, LaoName, EngName, Retail_Price, Wholesale_Price, BuyPrice, Discount, SizeTypeID, SizeType, Size) => {
    var list = [];
    list.push({
      ProductID: ProductID,
      ProductTypeID: ProductTypeID,
      ProductType: ProductType,
      CategoryID: CategoryID,
      Category: Category,
      BrandID: BrandID,
      Brand: Brand,
      Barcode: Barcode,
      LaoName: LaoName, 
      EngName: EngName, 
      Retail_Price: Retail_Price,
      Wholesale_Price: Wholesale_Price, 
      BuyPrice: BuyPrice, 
      Discount: Discount,
      SizeTypeID: SizeTypeID, 
      SizeType: SizeType, 
      Size: Size, 
    });
    getCategoryByProductTypeID(ProductTypeID);
    getBrandByCategoryID(CategoryID);
    setData(list);
    setShowUpdate(true);
  };

  async function getAllProductLimitTop10() {
    axios.get("/getAllProductLimit10").then((response) => {
      let Data = response?.data;
      setProductList(Data);
      setShowData(Data);
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getCategoryByProductTypeID(value) {
    let token = localStorage.getItem("token");
    await axios.get(`/getCategoryByProductTypeID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let temOrder = response?.data;
      if(temOrder.length > 0){
        setCategoryList(temOrder);
      } else {
        setCategoryList([]);
      }
    }).catch((err) => {
        setCategoryList([]);
        console.log(err);
    });
}

  async function getBrandByCategoryID(value) {
    let token = localStorage.getItem("token");
    await axios.get(`/getBrandByCatetoryID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let temOrder = response?.data;
      if(temOrder.length > 0){
        setBrandList(temOrder);
      } else {
        setBrandList([]);
      }
    }).catch((err) => {
        setBrandList([]);
        console.log(err);
    });
}

  async function getAllProductType() {
    axios
      .get("/getAllProductType")
      .then((response) => {
        let Data = response?.data;
        setProductTypeList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllSizeType() {
    axios
      .get("/getAllSizeType")
      .then((response) => {
        let Data = response?.data;
        setSizeTypeList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getAllProductLimitTop10();
    getAllProductType();
    getAllSizeType();
  }, []);

  return (
    <ProductContext.Provider value={{userID,ProductList, setProductList, setShowData, ProductTypeList, CategoryList, setCategoryList, BrandList, setBrandList, SizeTypeList, setSizeTypeList, data}}>
      <div className="formContent">
        <CreateProduct show={show} onClick={handleClose}/>
        <UpdateProduct show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສິນຄ້າ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາດ້ວຍ Barcode" name="Barcode" onChange={(e) => HandleSearchProductByBarcode(e)} />
            <input placeholder="ຄົ້ນຫາ ຊື່ພາສາລາວ" name="LaoName" onChange={(e) => HandleSearchProductByLaoName(e)} />
            <input placeholder="ຄົ້ນຫາ ຊື່ພາສາອັງກິດ" name="EngName" onChange={(e) => HandleSearchProductByEngName(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ຊື່ ພາສາລາວ</th>
                <th>ຊື່ ພາສາອັງກິດ</th>
                <th>ປະເພດສິນຄ່້າ</th>
                <th>ໝວດສິນຄ້າ</th>
                <th>ຫຍີ່ຫໍ້</th>
                <th>ລາຄາຊື້</th>
                <th>ລາຄາຂາຍ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.LaoName }</td>
                        <td >{x.EngName}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >{x.Brand}</td>
                        <td >{x.BuyPrice }</td>
                        <td >{x.Retail_Price}</td>
                        <td >{x.Discount}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.ProductID} type="button" onClick={() => openUpdate(x.ProductID, x.ProductTypeID, x.ProductType, x.CategoryID, x.Category, x.BrandID, x.Brand, x.Barcode, x.LaoName, x.EngName, x.Retail_Price, x.Wholesale_Price, x.BuyPrice, x.Discount, x.SizeTypeID, x.SizeType, x.Size)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

export {ProductContext};
export default Product;
