import React, { useState, useEffect, useRef  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import '../../../css/ProductReport.css';
import { API } from "../../../services/api";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../img/logo/ManeeCafeLogo.jpg';
import axios from "axios";
axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const productList = this.props.TemProductList;
      let i =0;
      return (
            <div className="ProductReportContent">
                <div className="ReportHeader">
                  <div className="ReportHeaderBody">
                      <img className="profile-img" src={logo} />
                      <div className="ReportTitle">
                        <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                        </div>
                        <div className="titleHeader2">
                            <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                        </div>
                        <div className="titleHeader2">
                            <span>**********</span>
                        </div>
                        <div className="titleHeader3">
                            <span>ລາຍງານ ລາຍການສິນຄ້າ</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ReportBody">
                    <div className="ReportBodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="Barcode">Barcode</span>
                        <span className="ProductType">ປະເພດສິນຄ້າ</span>
                        <span className="Category">ໝວດສິນຄ້າ</span>
                        <span className="Brand">ຫຍີ່ຫໍ້</span>
                        <span className="SizeType">Size Type</span>
                        <span className="Size">ຂະໜາດ</span>
                        <span className="Retail_Price">ລາຄາ</span>
                    </div>
                    <div className="HeadLine">
                      <div className="footer-line"/>
                    </div>
                    <div className="ReportDetail">
                        {
                            productList && productList.map((x, y) => {
                              if(y < 19){
                                return (
                                  <div className="Items">
                                      <div className="Detail">
                                          <div className="mds">
                                              <span className="NO">{(y + 1) }</span>
                                              <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                              <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                              <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                              <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                              <span className="SizeType">{x.SizeType.length > 10 ? x.SizeType.substring(0, 10) + '...' : x.SizeType}</span>
                                              <span className="Size">{x.Size}</span>
                                              <span className="Retail_Price">{currencyFormat(x.Retail_Price)}</span>
                                          </div>
                                          <div className="ProductName">
                                              <span className="LaoName">{x.LaoName}</span>
                                          </div>
                                          <div className="p-line"/>
                                      </div>
                                  </div>
                                )
                              } else if (i == 0) {
                                i++;
                                return (
                                  <div className="Items">
                                      <div className="Detail">
                                      <div className="newline"></div>
                                            <div className="mds">
                                              <span className="NO">{(y + 1) }</span>
                                              <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                              <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                              <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                              <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                              <span className="SizeType">{x.SizeType}</span>
                                              <span className="Size">{x.Size}</span>
                                              <span className="Retail_Price">{currencyFormat(x.Retail_Price)}</span>
                                          </div>
                                          <div className="ProductName">
                                              <span className="LaoName">{x.LaoName}</span>
                                          </div>
                                          <div className="p-line"/>
                                      </div>
                                  </div>
                                )
                              } else {
                                  i++;
                                  if(i== 24)
                                      i=0;
                                  return (
                                    <div className="Items">
                                        <div className="Detail">
                                          <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                                <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                                <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                                <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                                <span className="SizeType">{x.SizeType}</span>
                                                <span className="Size">{x.Size}</span>
                                                <span className="Retail_Price">{currencyFormat(x.Retail_Price)}</span>
                                            </div>
                                            <div className="ProductName">
                                              <span className="LaoName">{x.LaoName}</span>
                                          </div>
                                          <div className="p-line"/>
                                        </div>
                                    </div>
                                  )
                              }
                              
                            })
                        }
                    </div>
                    <div className="ReportSign">
                        
                        <span className="Manager">ຜູ້ກວດກາ</span>
                        <span className="StockAdmin"></span>
                        <span className="finance">ຜູ້ຄິດໄລ່</span>
                    </div>
                </div>
            </div>
      );
  }
}


function ProductReport() {

  const [ProductList, setProductList] = useState([]);
  const [ProductTypeList, setProductTypeList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [ProductTypeID, setProductTypeID] = useState(0);
  const [ProductType, setProductType] = useState(""); 
  const componentRef = useRef(); 

  async function HandleSearch () {
    if(ProductTypeID > 0){
      getProductByProductTypeID(ProductTypeID);
    } else {
      getAllProduct();
    }
  }

  const handleSelectProductType = (e) => {
    var data = ProductTypeList.filter(function (item) {
        return item.ProductType == e.target.value
    })
    if (data.length > 0) {
        setProductType(data[0].ProductType);
        setProductTypeID(data[0].ProductTypeID);
    } else {
      setProductType("");
      setProductTypeID("");
    }
  };

  async function getProductByProductTypeID (ProductTypeID) {
    if(ProductTypeID){
      let token = localStorage.getItem("token");
      await axios.put(`/getAllProductByProductTypeID/${ProductTypeID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let Data = response?.data;
        if(Data.length > 0){
          setShowData(Data);
        }else {
          setShowData(ProductList);
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
      setShowData(ProductList);
    }
  }

  async function getAllProduct() {
    axios.get("/getAllProduct").then((response) => {
      let Data = response?.data;
      setProductList(Data);
      setShowData(Data);
    }).catch((error) => {
      console.log(error);
    });
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function getAllProductType() {
    axios
      .get("/getAllProductType")
      .then((response) => {
        let Data = response?.data;
        setProductTypeList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getAllProductType();
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສິນຄ້າ</span>
          </div>
          <div className="txtSearch">
            <div className="Location">
                  <select name="ProductType" onChange={(e) => handleSelectProductType(e)}
                      className={`input form-control`}>
                      <option
                          key={""}
                          value={""}
                      >
                          {'ເລືອກປະເພດສິນຄ້າ'}
                      </option>,
                      {ProductTypeList && ProductTypeList.map(item => (
                          <option
                              key={item.ProducTypeID}
                              value={item.ProductType}
                          >
                              {item.ProductType}
                          </option>
                      ))}
                  </select>
              </div>
            <button className="btn btn-primary" onClick={(e) => HandleSearch(e)} >ຄົ້ນຫາ</button>
            <button className="btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ຊື່ ພາສາລາວ</th>
                <th>ຊື່ ພາສາອັງກິດ</th>
                <th>ປະເພດສິນຄ່້າ</th>
                <th>ໝວດສິນຄ້າ</th>
                <th>ຫຍີ່ຫໍ້</th>
                <th>ປະເພດຂະໜາດ</th>
                <th>ຂະໜາດ</th>
                <th>ລາຄາຊື້</th>
                <th>ລາຄາຂາຍ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={x}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.LaoName }</td>
                        <td >{x.EngName}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >{x.Brand}</td>
                        <td >{x.SizeType}</td>
                        <td >{x.Size}</td>
                        <td >{x.BuyPrice }</td>
                        <td >{x.Retail_Price}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
            />
        </div>
      </div>
  );
};

export default ProductReport;
